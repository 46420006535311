import { AboutHero } from '@/AboutPages/AboutHero';
import { Article } from '@/Article';
import { Seo } from '@/layout/Seo';
import { css } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';
import { Fragment } from 'react';

const DerekFruetel: Page = () => {
    return (
        <Fragment>
            <Seo
                title="Derek Fruetel – Integrate Institute"
                description="Meet Derek Fruetel chiropractor in Burnsville Minnesota"
            />
            <AboutHero>
                <StaticImage
                    src="../../../assets/images/about-us/derek.jpg"
                    alt="dr derek fruetel"
                    width={504}
                    height={568}
                />
            </AboutHero>
            <Article>
                <h1
                    css={css`
                        margin: 0;
                    `}
                >
                    Derek Fruetel,
                    <br />
                    Chiropractor
                </h1>
                <h3
                    css={css`
                        margin: 0 0 32px;
                    `}
                >
                    Doctor of Chiropractic
                </h3>
                <p>
                    Dr. Derek was born and raised in Litchfield, MN where he was a three sport
                    athlete. He continued his academic & wrestling career at Minnesota State
                    University Moorhead where he obtained a Bachelors of Exercise Science with three
                    minors; strength & conditioning, wellness and coaching.
                </p>

                <p>
                    Dr. Derek studied at NWHSU for his Doctorate of Chiropractic with extended
                    training in Sports Chiropractic where he developed a movement based approach. He
                    had the opportunity to work with a spectrum from professional athletes to the
                    weekend warrior with goals to not only get out of pain but to increase
                    performance emphasizing education on how to move better!
                </p>

                <p>
                    As a fitness enthusiast, Dr. Derek has a passion for working with athletes,
                    bodybuilders and active people of all ages. In his free time, he loves pursuing
                    his body building career, staying active, enjoying outdoors and spending time
                    with his family and friends.
                </p>
            </Article>
        </Fragment>
    );
};

export default DerekFruetel;
